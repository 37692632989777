import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';

// Helper
import {checkCookie} from '../../Helper/Cookies';
import DeviceUUID from '../../Helper/device-uuid';
import { Button } from 'bootstrap';

// Properties
var properties = require('../../properties.json');

class Header extends Component {
    state = {
        // Get Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : "",

        // Open User Menu
        menuOpened: false,
        
        // Open Responsive Menu
        mobileMenuOpened: false,

        macID: new DeviceUUID().get(),
        companyID: properties.company_id,
        branchID: properties.branch_id,
    }

    componentDidMount() {
        // If The Cookies Is Removed, Remove The Data Stored In The Browser
        if (!checkCookie('email')) {
            window.localStorage.removeItem('currentUser');
        }
    }

    // Logout of The System
    logout = (e) => {
        e.preventDefault();

        document.cookie = "email =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        window.localStorage.removeItem('currentUser');

        var studentDataSignOut = {
            companyId: this.state.companyID,
            branchId: this.state.branchID,
            id: this.state.currentUser.studentData.id,
            macId: this.state.macID
        };

        // Send the data to the server
        fetch(properties.api_path + "/authenticationStudent/logout", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(studentDataSignOut)
        })
        .then(res => {return res.json()})
        .then(done => {
            
        })
        .catch(error => {
            console.error('Error:', error)

            alert("خطأ في الإتصال");
        });

        setTimeout(() => {
            window.location.href = "/";
        }, 500);
    }
        

    render() {

        // CSS
        require('./Header.scss');

        let userName = this.state.currentUser ? this.state.currentUser.studentData.fullName : "";
        const downloadFile = (
            filePath,
            fileName = 'Lumen.exe',
          ) => {
            fetch('https://cors-anywhere.herokuapp.com/' + filePath, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/pdf',
              },
            })
              .then(response => response.blob())
              .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
        
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
        
                document.body.appendChild(link);
        
                link.click();
        
                link.parentNode.removeChild(link);
              });
          };

        return(
            <header className="main-header">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link to="/" className="navbar-brand">
                            <img src={require(`../../assets/img/${properties.teacher.logo}`).default} alt={properties.site_name} />
                        </Link>
                        
                        <button onClick={() => {this.setState({mobileMenuOpened: !this.state.mobileMenuOpened})}} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="fas fa-bars"></span>
                        </button>

                        <div className={`collapse navbar-collapse ${(this.state.mobileMenuOpened) ? 'show' : ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <NavLink exact activeClassName="selected" className="nav-link" to="/">الرئيسية</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link" to="/gallery">المعرض</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="selected" className="nav-link" to="/free-videos">الڤيديوهات المجانية</NavLink>
                                </li>

                               <li className="nav-item">
                     
                               <Link className="nav-link  " to="/"
                                     onClick={() => downloadFile(properties.download_desktop_app)}
                                 >
                                      تحميل برنامج المنصه
                                           </Link>                             
                                              </li>
                                {/* {(this.state.currentUser) ? (
                                    // User Logged In
                                    <>
                                        <li className="nav-item">
                                            <NavLink activeClassName="selected" className="nav-link" to="/months">ڤيديوهات الشرح</NavLink>
                                        </li>
                                        <li className="nav-item dropdown user-menu">
                                            <a className="nav-link dropdown-toggle" onClick={(e) => {e.preventDefault(); this.setState({menuOpened: !this.state.menuOpened});}} href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {userName} <img src={(this.state.currentUser.studentData.imagePath) ? `${properties.uploads_path}/${this.state.currentUser.studentData.imagePath}` : require(`../../assets/img/pp.jpg`).default} alt={userName} />
                                            </a>
                                            <div className={`dropdown-menu ${(this.state.menuOpened) ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to="/profile">حسابي <i className="far fa-id-card"></i></Link>
                                                <div className="dropdown-divider"></div>
                                                <Link className="dropdown-item" to="/exams">إمتحاناتي <i className="far fa-question-circle"></i></Link>
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item" onClick={this.logout} href="/">تسجيل الخروج <i className="fas fa-sign-out-alt"></i></a>
                                            </div>
                                        </li>
                                    </>
                                ): (
                                    // User Logged Out
                                    <>
                                        <li className="nav-item">
                                            <Link className="nav-link button sign-in" to="/sign-in">تسجيل الدخول</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link button sign-up" to="/sign-up">تسجيل جديد</Link>
                                        </li>
                                    </>
                                )} */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;