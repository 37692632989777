import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Connector from './connector';

ReactDOM.render(
  <React.Fragment>
    <Connector />
  </React.Fragment>,
  document.getElementById('root')
);