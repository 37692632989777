/* eslint-disable no-loop-func */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';


// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';

// Helper
import {setCookie, checkCookie} from '../../Helper/Cookies';

// Properties
var properties = require('../../properties.json');

class SingleExam extends Component {
    state = {
        // Input Values
        username: null,
        password: null,
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).studentData : {},
        
        // Exam ID
        examID: (this.props.match.params.id) ? parseInt(this.props.match.params.id) : 0,

        // Sign Up Process Indicator
        buttonText: "حل الإمتحان",
        buttonColor: "",

        // Message
        msg: {
            msgText: null,
            msgType: null,
            msgIcon: null
        },

        isSolved: false,

        // Get Exam
        exam: []
    }

    componentDidMount() {        
        window.scrollTo(0, 0);

        // Redirect To Sign In Page If The User Is Already Logged Out & Remove The Data If It Exists
        if (!checkCookie('email')) {
            window.localStorage.removeItem('currentUser');
            window.location.href = "/sign-in";
        }
        
        // If this exam is already solved!
        fetch(properties.api_path + "/exams/checkExams", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: this.state.examID,
                studentId: this.state.currentUser.id
            })
        })
        .then(res => {return res.json()})
        .then(isSolved => {
            if (isSolved.msgType === "fail") {
                window.location.href = "/exams";
            }
        });


        // Get Exam From API
        fetch(properties.api_path + "/exams/findExams", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: this.state.examID
            })
        })
        .then(res => {return res.json()})
        .then(fetchExam => {
            this.setState({
                exam: fetchExam
            }, () => {
                if (this.state.exam.questionsList.length) {
                    var tempppp = this.state.exam;
                    tempppp.parent = tempppp.id;
                    tempppp.id = null;
                    tempppp.studentId = this.state.currentUser.id;
                    tempppp.companyId = this.state.companyID;

                    for (var p = 0; p < this.state.exam.questionsList.length; p++) {
                        for (var g = 0; g < this.state.exam.questionsList[p].answersdtoList.length; g++) {
                            tempppp.questionsList[p].answersdtoList[g].id = null;
                        }

                        tempppp.questionsList[p].branchId = this.state.branchID;
                        tempppp.questionsList[p].companyId = this.state.companyID;
                        tempppp.questionsList[p].parent = tempppp.questionsList[p].id;
                        tempppp.questionsList[p].studentId = this.state.currentUser.id;
                        tempppp.questionsList[p].id = null;
                    }

                    this.setState({
                        exam: tempppp
                    });
                }
            });
        })
        .catch(error => {
            console.error('Error:', error)
        });
    }

    render() {
        document.title = properties.site_name + " - " + this.state.exam.examName;

        // Import CSS
        require('./Exams.scss');

        let getQuestions = (this.state.exam.questionsList) ? this.state.exam.questionsList.map((ques, q) => {
            let answers = ques.answersdtoList.map((ans, a) => {
                if (this.state.exam.questionsList[q].questionType === 0) {
                    return (
                        <div className="answer">
                            <div className="radio">
                                <input required={true} type="radio" id={`q${q}a${a}`} name={`q${q}a${a}`} value={this.state.exam.questionsList[q].answersdtoList.answer} onChange={(e) => {
                                    let temp = this.state.exam;
                                    
                                    for (var c = 0; c < temp.questionsList[q].answersdtoList.length; c++) {
                                        temp.questionsList[q].answersdtoList[c].selection = 0;
                                    }

                                    temp.questionsList[q].answersdtoList[a].selection = 1;

                                    this.setState({
                                        exam: temp
                                    })
                                }} />
                                <label for={`q${q}a${a}`}><span> ({a+1}) {this.state.exam.questionsList[q].answersdtoList[a].answer}</span></label>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="answer">
                            <input name={`q${q}a`} type="text" placeholder="الإجابة" onChange={(e) => {
                                let temp = this.state.exam;
                                temp.questionsList[q].answersdtoList[a].answer = e.target.value;
                                temp.questionsList[q].answersdtoList[a].selection = 1;

                                this.setState({
                                    exam: temp
                                })
                            }} />
                        </div>
                    );
                }
            });

            return (
                <div className="q_item">
                <span className="index">سؤال رقم {q+1}</span>
                    <h2 className="question-name">{ques.questionName}</h2>
                        <div className="answers">
                            {answers}
                        </div>
                        {/* <div className="error">يجب الإجابة على هذا السؤال</div> */}
                </div>  
            );
        }): "";

        let solveExam = (e) => {
            e.preventDefault();

            var _this = this;

            this.setState({
                buttonText: "جاري حساب النتيجة ...",
                buttonColor: "loading"
            });

            // Send the data to the server
            fetch(properties.api_path + "/exams/saveExams", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(this.state.exam)
            })
            .then(res => {return res.json()})
            .then(done => {
               _this.setState({
                   buttonText: "حل الإمتحان",
                   buttonColor: "",
                   msg: {
                       msgText: done.msg,
                       msgType: (done.msgType === "success") ? "success": "warning",
                       msgIcon: (done.msgType === "success") ? "fas fa-check-circle" : "fas fa-times-circle"
                   }
               });

               setTimeout(() => {
                    window.location.href = "/exams";
               }, 500);
            })
            .catch(error => {
                console.error('Error:', error)

                _this.setState({
                   buttonText: "حل الإمتحان",
                   buttonColor: "",
                   msg: {
                       msgText: "خطأ في الإتصال",
                       msgType: "warning",
                       msgIcon: "fas fa-times-circle"
                   }
               });

            });
        }

        return (
            <>
                <Header />
                <PageHeader pageName={this.state.exam.examName} />

                <div className="container">
                    <div className="questions">
                        {getQuestions}      
                        
                        <a href="/" className={`show-score ${this.state.buttonColor}`} onClick={(e) => solveExam(e)}>{this.state.buttonText}</a>   
                        {(this.state.msg.msgText !== null) ? (
                            <div className={`message ${this.state.msg.msgType}`}>{this.state.msg.msgText} <i className={this.state.msg.msgIcon}></i></div>
                        ): ""}
                    </div> 

                </div>              

                <Footer />
            </>
        );
    }
} 

export default SingleExam;