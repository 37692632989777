import React, {Component} from 'react';

class PageHeader extends Component {
    render() {

        // Import CSS
        require('./PageHeader.scss');

        return (
            <div className="page-header">
                <div className="container">
                    <h2>{this.props.pageName}</h2>
                </div>
            </div>
        );
    }
}

export default PageHeader;