import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';
import { Player } from 'video-react';
import DeviceUUID from '../../Helper/device-uuid';
import { Dropdown, DropdownButton } from 'react-bootstrap';


// Properties
var properties = require('../../properties.json');

class DoctorVideos extends Component {    
    state = {
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : "",

        // Tabs
        openAttchment: false,
        openExams: false,

        // First Lesson
        currentLessonID: (this.props.match.params.id) ? parseInt(this.props.match.params.id) : 0,

        // Free Lessons
        freeLessons: [],
        getPeriodsList: [],
        getMaterialsList: [],
        getPeriods: [],
        selectedPeriod: null,
        selectedMaterial: null,
        selectedPeriodId: null,
        // Get Documents
        documents: [],

        // Get Exams
        exams: [],

        comment: null,
        reply: null,
        currentReplyID: null,

        // All Comments
        comments: [],
        currentVid: null,
        isDesktop: new DeviceUUID().parse().isDesktop,
        loading: 'برجاء الإنتظار ... جاري تحميل الفيديو',
        currentController: null
    }

    getPeriod(materialId) {
        fetch(properties.api_path + "/periods/findPeriod", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
          
            materialId: materialId
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((grades) => {
            console.log(grades);
            this.setState({ getPeriods: grades });
          });
      }

      getMaterialByStage(stageId){
        var data =JSON.parse(window.localStorage.getItem('currentTeacher'));
        fetch(properties.api_path + "/lockup/materialListbystage", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: stageId
            })
        })
        .then(res => {return res.json()})
        .then(grades => {
            console.log(grades);
            this.setState({getMaterialsList : grades});
        })
     }
    
    getExams = (lessonID) => {

        // Get Exams From API
            fetch(properties.api_path + "/exams/findExamsForStudent", {
                    method: "POST",
                    headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                            branchId: this.state.branchID,
                            companyId: this.state.companyID,
                            studentId: this.state.currentUser.id,
                            schoolStagesId: this.state.currentUser.schoolStagesId,
                            lessonId: lessonID
                    })
            })
            .then(res => {return res.json()})
            .then(fetchExams => {
                    this.setState({
                            exams: fetchExams
                    });
            })
            .catch(error => {
                    console.error('Error:', error)
            });
    }
    
    videoToBlob = (vidLink) => {
            if (this.state.currentController) {
                    this.state.currentController.abort();
            }

            var controller = new AbortController();
            var signal = controller.signal;

            // Convert URL To Blob
            fetch(vidLink, {signal}).then(r => r.blob()).then(vid => {
                    var videoUrl = window.URL.createObjectURL(vid);
                    this.setState({
                            currentVid: videoUrl,
                            loading: ''
                    });
            });

            this.setState({
                    currentController: controller
            });

            document.addEventListener('click', () => {
                    URL.revokeObjectURL(this.state.currentVid);
            });
    }

    getComments = (id) => {
            // Get Comments From API
            fetch(properties.api_path + "/comments/allComments", {
                    method: "POST",
                    headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                            lessonId: id
                    })
            })
            .then(res => {return res.json()})
            .then(fetchComments => {
                    this.setState({
                            comments: fetchComments
                    });
            })
    }

    deleteComment = (id, e) => {
            e.preventDefault();

            // Confirm Deletion
            var con = window.confirm("هل أنت متأكد من أنك تريد حذف هذا العنصر");

            if (con) {
                    // Delete Media By ID
                    fetch(properties.api_path + "/comments/deleteComments", {
                            method: "POST",
                            headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                    id: id
                            })
                    })
                    .then(res => {return res.json()})
                    .then(deleteCommentByID => {
                            this.setState({resend: true});

                            alert("تم الحذف بنجاح")
                    });
            }
    }
    
    sendReply = (e) => {
            e.preventDefault();

            fetch(properties.api_path + "/comments/saveComments", {
                    method: "POST",
                    headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json; charset=utf-8',
                    },
                    body: JSON.stringify({
                            companyId: this.state.companyID,
                            branchId: this.state.branchID,
                            lessonId: this.state.currentLessonID,
                            studentId: this.state.currentUser.studentData.id,
                            comment: this.state.reply,
                            parent: this.state.currentReplyID
                    })
            })
            .then(res => {return res.json()})
            .then(done => {
                    if (done.msgType === "success") {
                            this.setState({
                                    resend: true,
                                    currentReplyID: null,
                                    reply: null
                            });
                    }
        })
            .catch(error => {
                    console.error('Error:', error)
            });
    }
    
    addComment = (e) => {

            e.preventDefault();
        
            fetch(properties.api_path + "/comments/saveComments", {
                    method: "POST",
                    headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json; charset=utf-8',
                    },
                    body: JSON.stringify({
                            companyId: this.state.companyID,
                            branchId: this.state.branchID,
                            lessonId: this.state.currentLessonID,
                            studentId: this.state.currentUser.studentData.id,
                            comment: this.state.comment,
                            parent: this.state.currentReplyID
                    })
            })
            .then(res => {return res.json()})
            .then(done => {
                    if (done.msgType === "success") {
                            this.setState({
                                    resend: true,
                                    currentReplyID: null,
                                    reply: null,
                                    comment: null
                            });
                    }
        })
            .catch(error => {
                    console.error('Error:', error)
            });
    }

    componentDidMount() {
        document.title = properties.site_name + ` - الدروس `;
        window.scrollTo(0, 0);
        console.log(this.state.currentUser.schoolStageId)
        this.getMaterialByStage(49)

        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        
        // Get Free Lessons From API
        // fetch(properties.api_path + "/lesson/lessonList", {
        //     method: "POST",
        //     headers: {
        //         'Authorization': 'Bearer ' + this.state.currentUser.token,
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
                
        //         teacherId : this.state.currentLessonID ,
        //         materialId : 77 ,
        //         studentId:  (this.state.currentUser) ? this.state.currentUser.studentData.id : null,
        //         branchId: this.state.branchID,
        //         companyId: this.state.companyID,
        //         schoolStageId: (this.state.currentUser) ? this.state.currentUser.studentData.schoolStagesId : null
        //     })
        // })
        // .then(res => {return res.json()})
        // .then(fetchFreeLessons => {
        //     this.setState({
        //         freeLessons: fetchFreeLessons
        //     });
        //  console.log(this.state.freeLessons)
        //     if (this.state.currentLessonID === 0) {
        //         if (this.state.freeLessons.length && this.state.freeLessons[0].videoLink) {
        //             this.setState({
        //                 currentLessonID: (this.state.freeLessons.length) ? this.state.freeLessons[0].id : 0
        //             }, () => {
        //                 this.getComments(this.state.currentLessonID);
        //                 this.videoToBlob(`${properties.uploads_path}/video.php?videoSRC=${this.state.freeLessons[0].videoLink}`)
        //                 this.getExams(this.state.currentLessonID);
        //             });
        //         }
        //     } else {
        //         let cindex = this.state.freeLessons.findIndex(id => id.id === this.state.currentLessonID);
        //         if (this.state.freeLessons.length && this.state.freeLessons[cindex].videoLink) {
        //             this.getComments(this.state.currentLessonID);
        //             this.videoToBlob(`${properties.uploads_path}/video.php?videoSRC=${this.state.freeLessons[cindex].videoLink}`)
        //             this.getExams(this.state.currentLessonID);
        //         }
        //     }

        //     // Get Free Documents By Lessons From API On Load
        //     fetch(properties.api_path + "/documents/allDocuments", {
        //         method: "POST",
        //         headers: {
        //             'Authorization': 'Bearer ' + this.state.currentUser.token,
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({
        //             lessonId: this.state.currentLessonID
        //         })
        //     })
        //     .then(res => {return res.json()})
        //     .then(fetchDocuments => {
        //         this.setState({
        //             documents: fetchDocuments
        //         });
        //     });
        // });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.resend !== this.state.resend) {
            this.getComments(this.state.currentLessonID);

            this.setState({resend: false});
        }
    }

    render() {        
        // Import CSS
        require('../Videos/Videos.scss');

        let openReply = (e, comment) => {
            e.preventDefault(); 
            if (this.state.currentReplyID === comment.id) {
                this.setState({
                    currentReplyID: null,
                    reply: null
                })
            } else {
                this.setState({
                    currentReplyID: comment.id,
                    reply: null
                })
            }
        }

        let finalComments = this.state.comments.map(comment => {
            return {
                id: comment.id,
                name: comment.studentName,
                profile: comment.profilePicture,
                body: comment.comment,
                studentID: comment.studentId,
                parent: (comment.parent) ? comment.parent : 0
            }
        })

        let getReplys = (parentID) => {
            var allReplys = finalComments.map(comment => {
                if (comment.parent !== 0 && comment.parent === parentID) {
                    return (
                        <>
                            <div className="comment reply" key={comment.id}>
                                <section>
                                    {(comment.profile) ? <img src={(comment.profile) ? `${properties.uploads_path}/${comment.profile}` : (require(`../../assets/img/pp.jpg`).default)} alt={comment.name} /> : ""}
                                    <div className="comment-body">
                                        <h2>{(comment.name) ? comment.name : properties.teacher.name} {(!comment.name) ? <span>المدرس</span> : ""}</h2>
                                        <p>{comment.body}</p>
                                    </div>
                                </section>
                            </div>
                        </>
                    );
                }
            });

            return allReplys;
        }

        let getComments = finalComments.map(comment => {
            if (comment.parent === 0) {
                return (
                    <div className="comment" key={comment.id}>
                        <section>
                            <img src={(comment.profile) ? `${properties.uploads_path}/${comment.profile}` : (require(`../../assets/img/pp.jpg`).default)} alt={comment.name} />
                            <div className="comment-body">
                                <h2>{comment.name}</h2>
                                <p>{comment.body}</p>
                            </div>
                        </section>

                        {(this.state.currentUser && this.state.currentUser.studentData.id === comment.studentID) ? (
                            <div className="controls">
                                <a href="/" onClick={(e) => openReply(e, comment)}><i className="fas fa-reply"></i> رد</a>
                                <a href="/" className="delete" onClick={(e) => this.deleteComment(comment.id, e)}><i className="fas fa-trash"></i> حذف</a>
                            </div>
                        ): ""}

                        <div className="replys-comments">
                            {getReplys(comment.id)}
                        </div>

                        {(this.state.currentReplyID === comment.id) ? (
                            <div className="write-comment mb-5 write-reply">
                                <form onSubmit={(e) => this.sendReply(e)}>  
                                    <div className="row">
                                        <div className="col-md-9">
                                            <input type="text" name="write-comment" placeholder={`اكتب ردك على التعليق`} onChange={(e) => {this.setState({reply: e.target.value})}} />
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button">إرسال الرد <i className="fas fa-paper-plane"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ): ""}
                    </div>  
                );
            }
        });

        
        let getLessons = this.state.freeLessons.map((singleLesson, i) => 
            <div key={singleLesson.id} className={`video-item ${(this.state.currentLessonID === singleLesson.id) ? "active": ""}`} onClick={() => {
                this.setState({currentLessonID: singleLesson.id}, () => {
                    selectDocumentsByLesson();

                    this.setState({
                        currentVid: null,
                        loading: 'برجاء الإنتظار ... جاري تحميل الفيديو'
                    });

                    let cindex = this.state.freeLessons.findIndex(id => id.id === this.state.currentLessonID);
                    if (this.state.freeLessons[cindex].videoLink) {
                        this.videoToBlob(`${properties.uploads_path}/video.php?videoSRC=${this.state.freeLessons[cindex].videoLink}`)
                        this.getExams(this.state.freeLessons[cindex].id);
                    }

                    this.getComments(singleLesson.id);

                });
            }}>
                <h3><span className="index">{i + 1}.</span> {singleLesson.lessonName}</h3>
                <i className={`${(this.state.currentLessonID === singleLesson.id) ? "fas fa-check-circle": "far fa-circle"}`}></i>
            </div>
        );
        
        let currentLesson = this.state.freeLessons[this.state.freeLessons.findIndex(id => id.id === this.state.currentLessonID)];

        let getAllDocuments = this.state.documents.map((singleDocument, i) => {
            var getFileFullName = singleDocument.link.split("/")[1];
            var getRealNameAndExt = getFileFullName.split("__");
            var getFileSize = (parseFloat(getRealNameAndExt[2]) < 1000) ? parseFloat(getRealNameAndExt[2]).toFixed(2) + " KB" : (parseFloat(getRealNameAndExt[2]) / 1000).toFixed(2) + " MB";
            
            return (
                <div className="single-file">
                    <a href={`${properties.uploads_path}/${singleDocument.link}`} download>
                        <span className="name"><i className="fas fa-file-alt"></i>
                            {getRealNameAndExt[0] + getRealNameAndExt[3]}
                        </span>
                        <span className="size">{getFileSize}</span>
                    </a>
                </div>
            );
        });

        let getExamsList = this.state.exams.map(ex => 
            <div className="single-file">
                <Link to={`/exams/${ex.id}`}>
                    <span className="name"><i className="fas fa-file-alt"></i>
                        {ex.examName}
                    </span>
                    <span className="size">({ex.degree}) درجات</span>
                </Link>
            </div>
        );
        // let periods = this.state.getPeriods.map((period) => {
        //     return { label: period.name, value: period.id };
        //   });
        //   let materialList = this.state.getMaterialsList.map((period) => {
        //     return { label: period.name, value: period.id };
        //   });
          let materialList = this.state.getMaterialsList.map(period => 
            <option value={period.id}>{period.name}</option>    
        );
        let periods = this.state.getPeriods.map(period => 
            <option value={period.id}>{period.name}</option>    
        );
        let selectDocumentsByLesson = () => {
            
            // Get Free Documents By Lessons From API On Select Another Lesson
            fetch(properties.api_path + "/documents/allDocuments", {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.state.currentUser.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lessonId: this.state.currentLessonID
                })
            })
            .then(res => {return res.json()})
            .then(fetchDocuments => {
                this.setState({
                    documents: fetchDocuments
                });
            });
        }

        let currentPlayer = null;

        if (currentLesson) {
            if (currentLesson && currentLesson.youtubeLink) {
                currentPlayer = <iframe src={currentLesson.youtubeLink} title={currentLesson.lessonName} />;
            } else if (currentLesson && currentLesson.vimeoLink) {
                currentPlayer = <iframe src={currentLesson.vimeoLink} title={currentLesson.lessonName} />;
            } else {
                currentPlayer = <div className="overlay">{(this.state.isDesktop) ? <span>{this.state.loading}</span> : ""}<Player playsInline src={`${(this.state.isDesktop) ? this.state.currentVid: `${properties.uploads_path}/video.php?videoSRC=${currentLesson.videoLink}`}`} /></div>;
            }
        }

        return (
            <>
                <Header />
                <PageHeader pageName="الدروس " />
              
                <div className="month-videos">
           
                    <div className="container">
                    <div className="p-grid p-fluid row col-md-12 p-md-4">
                    <div className="col-md-5 p-md-4 ">
                                <select className="months" onChange={(e) => {
                                    this.setState({ selectedMaterial: e.target.value }
                                        ,this.getPeriod(e.target.value),
                                        console.log(e.target.value)
                                        
                                        )
                                }}>
                                    <option value={this.state.selectedMaterial}>إختر الماده</option>
                                    {materialList}
                                </select>
                            </div>
                    <div className="col-md-5 p-md-4">
                                <select className="months" onChange={(e) => {
                                   this.setState({ selectedPeriod: e.target.value },
                                  
                                )
                                }}>
                                    <option value={this.state.selectedPeriod}>إختر الفتره</option>
                                    {periods}
                                </select>
                            </div>
              
                 
                  {/* <div className="p-col-12 p-md-4">
                    <div className="p-inputgroup">
                      <span className="p-float-label"> */}
                        {/* <DropdownButton
                        id="dropdown-basic-button" title="إختر الفترة "
                          value={this.state.selectedPeriod}
                          options={periods}

                          placeholder="  إختر الفترة "
                          onChange={(e) =>
                            this.setState({ selectedPeriod: e.value },
                              this.getLessonsByStage(
                                this.state.selectedStage,
                                this.state.selectedMaterial,
                                e.value
                              ),
                            //  this.state.selectedType==1? 
                            
                            //  :null
                              )
                              
                              

                             
                          }
                          optionLabel="label"
                          filter
                          showClear
                          filterBy="label"
                      /  >  */}
                       {/* <select value={this.state.selectedMaterial} onChange={(e) => this.setState({selectedMaterial: e.value} , this.getPeriod(e.value) ,  console.log(this.state.selectedMaterial))
                 
                    } >

                     {materialList.map(
        (variant) => (
<option value={variant.name}>{variant.name}</option>
            ),
            )}


</select> */}
                                                      {/* <Dropdown className="p-dropDown-lg p-px-6 p-text-center" value={this.state.selectedMaterial} options={materialList} onChange={(e) => this.setState({selectedMaterial: e.value} , this.getPeriod(e.value))} name="type" placeholder="اختر الامتحان"/> */}

                        {/* {materialList.map(
        (variant) => (
            <Dropdown.Item href="#/action-1">{variant.name}</Dropdown.Item>
            ),
            )}
                      </DropdownButton> */}
                      {/* </span> */}
                    {/* </div> */}
                  </div>  </div>
                        {(this.state.freeLessons.length) ? (
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="videos-list">
                                        {getLessons}
                                    </div>
                                </div>

                                {(currentLesson) ? (
                                    <div className="col-md-8">
                                        <div className="video-details">
                                            {currentPlayer}
                                            
                                            <h2>{currentLesson.lessonName}</h2>
                                            <div className="description">{currentLesson.lessonDescription}</div>
                                            
                                            {/* Tabs */}
                                            <div className="attachments" onClick={() => this.setState({openAttchment: !this.state.openAttchment})}>
                                                <div className="title">
                                                    <span>ملحقات الدرس <i className="fas fa-paperclip"></i></span>
                                                    <i className="fas fa-caret-down"></i>
                                                </div>

                                                <div className={"files " + (this.state.openAttchment ? "active": "")}>
                                                    {getAllDocuments}
                                                </div>
                                            </div>

                                            <div className="attachments" onClick={() => this.setState({openExams: !this.state.openExams})}>
                                                <div className="title">
                                                    <span>الإمتحانات <i className="fas fa-scroll"></i></span>
                                                    <i className="fas fa-caret-down"></i>
                                                </div>

                                                <div className={"files " + (this.state.openExams ? "active": "")}>
                                                    {getExamsList}
                                                </div>
                                            </div>

                                            <div className="comments">
                                                <h3>التعليقات</h3>
                                                <hr />
                                                {getComments}

                                                {(this.state.currentUser) ? (
                                                    <div className="write-comment">
                                                        <form onSubmit={(e) => this.addComment(e)}>
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <input type="text" name="write-comment" placeholder="اكتب تعليقك" onChange={(e) => {this.setState({comment: e.target.value})}} />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <button type="submit">إرسال <i className="fas fa-paper-plane"></i></button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                ): ""}
                                            </div>                              
                                        </div>
                                    </div>
                                ): ""}
                            </div>
                        ): (
                            <div className="no-items">
                                <p>لا يوجد دروس </p>
                            </div>
                        )}
                    </div>
                {/* </div> */}
                <Footer />
            </>
        );
    }
}

export default DoctorVideos;