import React, {Component} from 'react';

// Components
import Header from '../../components/Header/Header';
import PageHeader from '../../components/Page-Header/PageHeader';
import Footer from '../../components/Footer/Footer';

// Helper
import {checkCookie} from '../../Helper/Cookies';

// Properties
var properties = require('../../properties.json');

class Profile extends Component {
    constructor(props) {
        super(props);
        this.ppInput = React.createRef();
    }

    state = {
        // Input Values
        fullName: null,
        city: null,
        cityState: null,
        studentPhone: null,
        parentPhone: null,
        parentJob: null,
        grade: null,
        gender: null,
        email: null,
        username: null,
        password: null,
        imgPath: null,
        companyID: properties.company_id,
        branchID: properties.branch_id,

        // Sign Up Process Indicator
        buttonText: "تحديث الحساب",
        buttonColor: "",

        // Select The Image
        imageSelected: false,

        uploadedImg: null,

        // Cities & States & Grades
        cities: [],
        states: [],
        grades: [],

        // Current User
        currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).studentData : {},

        // Message
        msg: {
            msgText: null,
            msgType: null,
            msgIcon: null
        }
    }

    getStates = (cityID) => {
        fetch(properties.api_path + "/lockup/statesList", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({id: this.state.city})
        })
        .then(res => {return res.json()})
        .then(states => {
            this.setState({states: states});
        })
        .catch(error => {
            console.error('Error:', error)
        })
    }

    componentDidMount() {
        document.title = properties.site_name + " - تحديث الحساب";

        // Redirect To Sign In Page If The User Is Already Logged Out & Remove The Data If It Exists
        if (!checkCookie('email')) {
            window.localStorage.removeItem('currentUser');
            window.location.href = "/sign-in";
        }

        // Get Cities From API
        fetch(properties.api_path + "/lockup/governorateList", {
            method: "POST"
        })
        .then(res => {return res.json()})
        .then(cities => {
            this.setState({cities: cities});
        })
        
        // Get Grades From API
        fetch(properties.api_path + "/lockup/levelList", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({branchId: this.state.branchID})
        })
        .then(res => {return res.json()})
        .then(grades => {
            this.setState({grades: grades});
        })
        
        // Get Students From API
        fetch(properties.api_path + "/student/studentList", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                branchId: this.state.branchID,
                companyId: this.state.branchID,
                id: this.state.currentUser.id
            })
        })
        .then(res => {return res.json()})
        .then(fetchStudent => {
            this.setState({
                fullName: fetchStudent[0].fullName,
                city: fetchStudent[0].governorateId,
                cityState: fetchStudent[0].stateId,
                studentPhone: fetchStudent[0].mobile,
                parentPhone: fetchStudent[0].fatherMobile,
                parentJob: fetchStudent[0].fatherJob,
                grade: fetchStudent[0].schoolStagesId,
                gender: fetchStudent[0].gender,
                email: fetchStudent[0].email,
                username: fetchStudent[0].userName,
                password: fetchStudent[0].password,
                imgPath: fetchStudent[0].imagePath
            }, () => {
                this.getStates(this.state.city);
            });
        });
    }

    render() {

        // Import CSS
        require('../Sign-Up/SignUp.scss');
        require('../Profile/Profile.scss');

        let cirtiesList = this.state.cities.map(city =>
            <option selected={this.state.city === city.id} key={city.id} value={city.id}>{city.name}</option>
        );

        let statesList = (Array.isArray(this.state.states)) ? this.state.states.map(cityState =>
            <option selected={this.state.cityState === cityState.id} key={cityState.id} value={cityState.id}>{cityState.name}</option>
        ) : [<option key={0} value="">لا يوجد مراكز</option>];
        
        let gradesList = (Array.isArray(this.state.grades)) ? this.state.grades.map(grade =>
            <option selected={this.state.grade === grade.id} key={grade.id} value={grade.id}>{grade.name}</option>
        ) : [<option key={0} value="">لا يوجد مراحل دراسية</option>];

        var updateAndGetState = (e) => {
            this.setState({city: parseInt(e.target.value)}, () => {
                this.getStates(this.state.city)
            });
        }

        var updateProfile = (e, img) => {
            let _this = this;
            e.preventDefault();

            this.setState({
                buttonText: "جاري تحديث الحساب ...",
                buttonColor: "loading"
            });

            // Send the PP to the server
            let pp = new FormData();
            pp.append('profile', img);

            fetch(properties.uploads_path + "/upload-pp.php", {
                method: "POST",
                body: pp
            })
            .then(res => {return res.json()})
            .then(done => {
               this.setState({
                   imgPath: done.pp
               }, () => {
                    var studentDataSignUp = {
                        id: this.state.currentUser.id,
                        companyId: this.state.companyID,
                        branchId: this.state.branchID,
                        fullName: this.state.fullName,
                        password: this.state.password,
                        mobile: this.state.studentPhone,
                        fatherMobile: this.state.parentPhone,
                        fatherJob: this.state.parentJob,
                        gender: this.state.gender,
                        userName: this.state.username,
                        stateId: this.state.cityState,
                        email: this.state.email,
                        schoolStagesId: this.state.grade,
                        imagePath: this.state.imgPath
                    };
                    
                    // Send the data to the server
                    fetch(properties.api_path + "/student/studentregister", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json; charset=utf-8',
                        },
                        body: JSON.stringify(studentDataSignUp)
                    })
                    .then(res => {return res.json()})
                    .then(done => {
                    _this.setState({
                        buttonText: "تحديث الحساب",
                        buttonColor: "",
                        msg: {
                            msgText: done.msg,
                            msgType: (done.msgType === "success") ? "success": "warning",
                            msgIcon: (done.msgType === "success") ? "fas fa-check-circle" : "fas fa-times-circle"
                        }
                    });
                    })
                    .catch(error => {
                        console.error('Error:', error)

                        _this.setState({
                        buttonText: "تحديث الحساب",
                        buttonColor: "",
                        msg: {
                            msgText: "خطأ في الإتصال",
                            msgType: "warning",
                            msgIcon: "fas fa-times-circle"
                        }
                    });
                    });
               });
            })
            .catch(error => {
                console.error('Error:', error)
            });
        }

        return (
            <>
                <Header />
                <PageHeader pageName="تعديل الحساب" />

                <div className="sign-up-page page">
                    <div className="container">
                        <form className="edit-profile registeration-form" onSubmit={(e) => updateProfile(e, this.state.uploadedImg)}>
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="profile-pic">
                                        <div className="upload-pp-circle" onClick={() => {
                                            this.ppInput.current.click();
                                        }}>
                                            {(!this.state.imageSelected) ? (
                                                <h3>
                                                    {(this.state.imgPath) ? (
                                                        <img className="pp" src={`${properties.uploads_path}/${this.state.imgPath}`} alt={this.state.fullName} />
                                                        ) : (
                                                        <i className="far fa-user-circle"></i>
                                                    )}
                                                    إختر الصورة الشخصية
                                                </h3>
                                            ): (
                                                <h3>
                                                    <i className="far fa-check-circle"></i>
                                                    تم إختيار الصورة
                                                </h3>
                                            )}
                                        </div>
                                        <input type="file" ref={this.ppInput} className="upload-pp" onChange={(e) => {
                                            if (e.target.value) {
                                                this.setState({
                                                    imageSelected: true,
                                                    uploadedImg: e.target.files[0]
                                                });
                                            } else {
                                                this.setState({
                                                    imageSelected: false
                                                });
                                            }
                                        }} accept="image/*" />
                                    </div>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="full-name">الإسم</label>
                                        <input value={this.state.fullName} required type="text" name="full-name" id="full-name" onChange={(e) => this.setState({fullName: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="city">المحافظة</label>
                                        <select required name="city" onChange={updateAndGetState}>
                                            <option value="">إختر محافظة</option>
                                            {cirtiesList}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="e-mail">البريد الإلكتروني</label>
                                        <input value={this.state.email} required type="email" name="e-mail" id="e-mail" onChange={(e) => this.setState({email: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="state">المركز</label>
                                        <select required name="state" onChange={(e) => this.setState({cityState: parseInt(e.target.value)})}>
                                            <option value="">إختر مركز</option>
                                            {statesList}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="stud-phone">تليفون الطالب</label>
                                        <input value={this.state.studentPhone} required type="tel" name="stud-phone" id="stud-phone" onChange={(e) => this.setState({studentPhone: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="gender">النوع</label>
                                        <select required name="gender" onChange={(e) => this.setState({gender: parseInt(e.target.value)})}>
                                            <option value="">إختر النوع</option>
                                            <option value={1} selected={this.state.gender === 1}>ذكر</option>
                                            <option value={2} selected={this.state.gender === 2}>أنثى</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="parent-phone">تليفون ولي الأمر</label>
                                        <input value={this.state.parentPhone} required type="tel" name="parent-phone" id="parent-phone" onChange={(e) => this.setState({parentPhone: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="grade">الصف الدراسي</label>
                                        <select required name="grade" onChange={(e) => this.setState({grade: parseInt(e.target.value)})}>
                                            <option value="">إختر المرحلة</option>
                                            {gradesList}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="parent-job">وظيفة ولي الأمر</label>
                                        <input value={this.state.parentJob} required type="tel" name="parent-job" id="parent-job" onChange={(e) => this.setState({parentJob: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="username">إسم المستخدم</label>
                                        <input value={this.state.username} required type="text" name="username" id="username" onChange={(e) => this.setState({username: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field">
                                        <label htmlFor="password">كلمة السر</label>
                                        <input value={this.state.password} required type="password" name="password" id="password" onChange={(e) => this.setState({password: e.target.value})} />
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-end">
                                    <button className={`submitReg ${this.state.buttonColor}`} name="submitReg" id="submitReg" type="submit">{this.state.buttonText}</button>
                                </div>
                                {(this.state.msg.msgText !== null) ? (
                                    <div className="col-md-12">
                                        <div className={`message ${this.state.msg.msgType}`}>{this.state.msg.msgText} <i className={this.state.msg.msgIcon}></i></div>
                                    </div>
                                ): ""}
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}

export default Profile;