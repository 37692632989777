import { Component } from "react";
import SignIn from "../Sign-In/SignIn"
import DoctorVideos from "../doctorsVideos/DoctorVideos"

class Private extends Component {

    // state = {
    //     companyID: properties.company_id,
    //     branchID: properties.branch_id,

    //     // Get Current User
    //     currentUser: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : "",
    // }

    render() {

        
        return (<>(  {localStorage.getItem('currentUser') !== ""})?<DoctorVideos/>:<SignIn/></>);

    }
} 

export default Private;